import React from 'react';

import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { startLogOut } from '../redux/auth';

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  CustomToggle.displayName = 'CustomToggle';

  return (
    <header className='d-flex align-items-center px-3'>
      <div className='d-flex align-items-center justify-content-between w-100'>
        <div className='logo-cont d-flex'>
          <Link to='/'>
            <img
              src='/images/speed-league-logo.svg'
              alt='Speed League Logo'
              className='full-logo d-none d-lg-block mb-2 pointer'
            />
          </Link>
          <Link to='/'>
            <img
              src='/images/speed-league-logo.svg'
              alt='Speed League Logo'
              className='tach-logo d-lg-none pointer'
            />
          </Link>
        </div>
        <div className='title-cont text-center'>
          <p className='overflow'>ADMIN</p>
          {auth.isLoggedIn && <p className='header-series text-gray overflow'>Welcome back, {auth.admin.first_name}</p>}
        </div>
        <div className='i-cont d-flex justify-content-end'>
          {auth.isLoggedIn && (
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <i className='fas fa-user-circle pointer' />
              </Dropdown.Toggle>
              <Dropdown.Menu className='text-center'>
                <Dropdown.Item onClick={() => history.push('/')}>Dashboard</Dropdown.Item>
                <Dropdown.Item onClick={() => dispatch(startLogOut())}>
                  <span><span className='fas fa-sign-out-alt mr-2' />Log Out</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
