import React from 'react';
import { format } from 'date-fns';

const Footer = () => (
  <footer className='text-center mt-5'>
    <p className='copyright text-gray py-2'>&copy; Speed League Fantasy {format(new Date(), 'yyyy')}</p>
  </footer>
);

export default Footer;
