import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  return (
    <Route {...rest} component={() => (
      isLoggedIn
        ? <Component {...rest} />
        : <Redirect to='/login' /> // Take them to their logged out home view
    )
    } />
  );
};

export default PrivateRoute;
