import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import Loading from '../components/reusable/Loading';
import Header from '../components/Header';
import Footer from '../components/Footer';

import lazyWithRetry from '../routers/lazyWithRetry';

const LogIn = lazyWithRetry(() => import('../components/auth/Login'));
const SignUp = lazyWithRetry(() => import('../components/auth/SignUp'));
const ForgotPassword = lazyWithRetry(() => import('../components/auth/ForgotPassword'));
const ResetPassword = lazyWithRetry(() => import('../components/auth/ResetPassword'));

const Dashboard = lazyWithRetry(() => import('../components/Dashboard'));
const Series = lazyWithRetry(() => import('../components/series/Series'));
const SeriesSeason = lazyWithRetry(() => import('../components/series/SeriesSeason'));
const ClassPage = lazyWithRetry(() => import('../components/series/ClassPage'));
const EventPage = lazyWithRetry(() => import('../components/series/EventPage'));
const NotFoundPage = lazyWithRetry(() => import('../components/NotFoundPage'));

const AppRouter = () => (
  <div className='min-vh-100 d-flex flex-column justify-content-between'>
    <div>
      <Header />
      <main>
        <Suspense fallback={<Loading main />}>
          <Switch>
            {/* AUTH */}
            <PublicRoute path={['/', '/login']} component={LogIn} exact />
            {/* <PublicRoute path='/forgot-password' component={ForgotPassword} exact /> */}

            {/* SIGN_UP - without token always reject */}
            <PublicRoute path='/sign-up/:token' component={SignUp} exact />
            <PublicRoute path='/forgot-password' exact component={ForgotPassword} />
            <PublicRoute path='/reset-password/:token?' exact component={ResetPassword} />

            <PrivateRoute path='/dashboard' component={Dashboard} exact />
            <PrivateRoute path='/series/:series_uuid' component={Series} exact />
            <PrivateRoute path='/series/:series_uuid/series_season/:series_season_uuid' component={SeriesSeason} exact />
            <PrivateRoute path='/class/:class_uuid' component={ClassPage} exact />
            <PrivateRoute path='/event/:event_uuid' component={EventPage} exact />

            <Route component={NotFoundPage} />

          </Switch>
        </Suspense>
      </main>
    </div>
    <Footer />
  </div>
);

export default AppRouter;
