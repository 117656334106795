import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from '../lib/axios';

const slice = createSlice({
  name: 'dashboard',
  initialState: {
    stats: {},
    upcomingEvents: [],
    loading: false,
  },
  reducers: {
    dashboardBegin: (dashboard) => {
      dashboard.loading = true;
    },
    dashboardEnd: (dashboard) => {
      dashboard.loading = false;
    },
    getDashboardStats: (dashboard, action) => {
      dashboard.stats = action.payload;
      dashboard.loading = false;
    },
    getUpcomingEvents: (dashboard, action) => {
      dashboard.upcomingEvents = action.payload;
      dashboard.loading = false;
    },
  },
});

const {
  dashboardBegin,
  dashboardEnd,
  getDashboardStats,
  getUpcomingEvents,
} = slice.actions;

// STATS
export const startGetDashboardStats = () => async (dispatch) => {
  dispatch(dashboardBegin());
  try {
    const response = await axios.get(`${baseURL}/stats`);
    dispatch(getDashboardStats(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(dashboardEnd());
    toast.error('Something went wrong.');
  }
};

// UPCOMING EVENTS
export const startGetUpcomingEvents = () => async (dispatch) => {
  dispatch(dashboardBegin());
  try {
    const response = await axios.get(`${baseURL}/events`);
    dispatch(getUpcomingEvents(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(dashboardEnd());
    toast.error('Something went wrong.');
  }
};

export default slice.reducer;
