import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import ls from 'local-storage';
import { ToastContainer } from 'react-toastify';
import configureStore from './redux/configureStore';
import AppRouter from './routers/AppRouter';
import { logIn } from './redux/auth';

import 'react-toastify/dist/ReactToastify.min.css';

export const store = configureStore();

const App = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  };

  if (ls('user')) {
    const user = ls('user');
    console.log('User: ', user);
    store.dispatch(logIn(user));
  }

  if (ls('authorization')) {
    ls.remove('authorization');
  }

  return (
    <Provider store={store}>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover />
      <Router>
        <ScrollToTop />
        <AppRouter />
      </Router>
    </Provider>
  );
};

export default App;
