import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import auth from './auth';
import dashboard from './dashboard';
import seriesData from './seriesData';

export default () => {
  const reducer = combineReducers({
    auth,
    dashboard,
    seriesData,
  });
  return configureStore({
    reducer,
    middleware: [
      ...getDefaultMiddleware(),
    ],
  });
};
