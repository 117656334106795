import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from '../lib/axios';
import ls from 'local-storage';

const slice = createSlice({
  name: 'auth',
  initialState: {
    admin: {},
    signUpToken: {},
    isLoggedIn: false,
    loading: false,
  },
  reducers: {
    authBegin: (auth) => {
      auth.loading = true;
    },
    authEnd: (auth) => {
      auth.loading = false;
    },
    getSignUpToken: (auth, action) => {
      auth.signUpToken = action.payload;
      auth.loading = false;
    },
    logIn: (auth, action) => {
      auth.admin = action.payload;
      auth.isLoggedIn = true;
      auth.loading = false;
    },
    // need BE route to delete token?
    logOut: (auth) => {
      auth.admin = {};
      auth.leagues = [];
      auth.league_invites = [];
      auth.isLoggedIn = false;
      auth.loading = false;
    },
  },
});

export const {
  authBegin,
  authEnd,
  getSignUpToken,
  signUp,
  logIn,
  logOut,
  getDashboard,
} = slice.actions;

// Log in
export const startLogIn = (values, setSubmitting) => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.post(`${baseURL}/login`, values);
    const { admin } = response.data;
    ls('user', admin);
    dispatch(logIn(admin));
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    toast.error('It looks like something went wrong. Please try again.');
    dispatch(authEnd());
    toast.error('Something went wrong.');
  }
};

// Get sign up token
export const startGetSignUpToken = (token) => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.get(`${baseURL}/register/${token}`);
    dispatch(getSignUpToken(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};

// Sign up
export const startSignUp = (values, setSubmitting) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.post(`${baseURL}/register`, values);
    dispatch(startLogIn(values));
    toast.success(`Sign up successful. Welcome to Speed League, ${values.first_name}!`);
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    toast.error('It looks like something went wrong. Please try again.');
    dispatch(authEnd());
  }
};

// LOG OUT
export const startLogOut = () => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.post(`${baseURL}/logout`);
    ls.clear();
    dispatch(logOut());
  } catch (error) {
    console.error(error.response);
    ls.clear();
    dispatch(logOut());
    toast.error('Something went wrong.');
  }
};

// Send Forgot Password Email
export const startSendForgotPasswordEmail = (payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.post(`${baseURL}/reset-password-email`, payload);
    toggle();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    toast.error('Something went wrong.');
  }
  dispatch(authEnd());
};

// Reset Password
export const startResetPassword = (payload, setSubmitting, history) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.post(`${baseURL}/reset-password`, payload);
    ls.clear();
    toast.success('Password updated. Please log back in.');
    history.push('/login');
  } catch (error) {
    console.error(error.response);
    if (error.response?.status === 406) {
      toast.error('Password reset token expired. Please check your email for a new link.');
      history.push('/login');
    } else {
      setSubmitting();
      toast.error('Something went wrong.');
    }
  }
  dispatch(authEnd());
};

export default slice.reducer;
