import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from '../lib/axios';

const slice = createSlice({
  name: 'seriesData',
  initialState: {
    series: [],
    racers: [],
    series_seasons: [],
    classes: [],
    currentClass: {
      rosters: [],
      race_teams: [],
      point_systems: [],
      prediction_systems: [],
      bonuses: [],
    },
    activity_logs: [],
    events: [],
    currentEvent: {
      classes: [],
    },
    loading: false,
  },
  reducers: {
    seriesBegin: (seriesData) => {
      seriesData.loading = true;
    },
    seriesEnd: (seriesData) => {
      seriesData.loading = false;
    },
    getAllSeries: (seriesData, action) => {
      seriesData.series = action.payload.sort((a, b) => (a.name > b.name ? 1 : -1));
      seriesData.loading = false;
    },
    createSeries: (seriesData) => {
      seriesData.loading = false;
    },
    getSeriesSeasons: (seriesData, action) => {
      seriesData.series_seasons = action.payload;
      seriesData.loading = false;
    },
    createSeriesSeason: (seriesData) => {
      seriesData.loading = false;
    },
    updateSeriesSeason: (seriesData) => {
      seriesData.loading = false;
    },
    getClassesBySeriesSeason: (seriesData, action) => {
      seriesData.classes = action.payload;
      seriesData.loading = false;
    },
    getClass: (seriesData, action) => {
      seriesData.currentClass = action.payload;
      seriesData.loading = false;
    },
    createClass: (seriesData) => {
      seriesData.loading = false;
    },
    updateClass: (seriesData) => {
      seriesData.loading = false;
    },
    getRacers: (seriesData, action) => {
      seriesData.racers = action.payload;
      seriesData.loading = false;
    },
    createRacer: (seriesData) => {
      seriesData.loading = false;
    },
    updateRacer: (seriesData) => {
      seriesData.loading = false;
    },
    createRoster: (seriesData) => {
      seriesData.loading = false;
    },
    updateRoster: (seriesData) => {
      seriesData.loading = false;
    },
    createRaceTeam: (seriesData) => {
      seriesData.loading = false;
    },
    updateRaceTeam: (seriesData) => {
      seriesData.loading = false;
    },
    createPointSystem: (seriesData) => {
      seriesData.loading = false;
    },
    updatePointSystem: (seriesData) => {
      seriesData.loading = false;
    },
    deletePointSystem: (seriesData) => {
      seriesData.loading = false;
    },
    createPredictionSystem: (seriesData) => {
      seriesData.loading = false;
    },
    updatePredictionSystem: (seriesData) => {
      seriesData.loading = false;
    },
    deletePredictionSystem: (seriesData) => {
      seriesData.loading = false;
    },
    createBonus: (seriesData) => {
      seriesData.loading = false;
    },
    updateBonus: (seriesData) => {
      seriesData.loading = false;
    },
    deleteBonus: (seriesData) => {
      seriesData.loading = false;
    },
    getActivityLogs: (seriesData, action) => {
      seriesData.activity_logs = action.payload;
      seriesData.loading = false;
    },
    createActivityLog: (seriesData) => {
      seriesData.loading = false;
    },
    updateActivityLog: (seriesData) => {
      seriesData.loading = false;
    },
    deleteActivityLog: (seriesData) => {
      seriesData.loading = false;
    },
    getEventsBySeriesSeason: (seriesData, action) => {
      seriesData.events = action.payload;
      seriesData.loading = false;
    },
    getEvent: (seriesData, action) => {
      seriesData.currentEvent = action.payload;
      seriesData.loading = false;
    },
    createEvent: (seriesData) => {
      seriesData.loading = false;
    },
    updateEvent: (seriesData) => {
      seriesData.loading = false;
    },
    deleteEvent: (seriesData) => {
      seriesData.loading = false;
    },
    submitRacerResults: (seriesData) => {
      seriesData.loading = false;
    },
  },
});



const {
  seriesBegin,
  seriesEnd,
  getAllSeries,
  createSeries,
  getSeriesSeasons,
  createSeriesSeason,
  updateSeriesSeason,
  getClassesBySeriesSeason,
  getClass,
  createClass,
  updateClass,
  getRacers,
  createRacer,
  updateRacer,
  createRoster,
  updateRoster,
  createRaceTeam,
  updateRaceTeam,
  createPointSystem,
  updatePointSystem,
  deletePointSystem,
  createPredictionSystem,
  updatePredictionSystem,
  deletePredictionSystem,
  createBonus,
  updateBonus,
  deleteBonus,
  getActivityLogs,
  createActivityLog,
  updateActivityLog,
  deleteActivityLog,
  getEventsBySeriesSeason,
  getEvent,
  createEvent,
  updateEvent,
  deleteEvent,
  submitRacerResults,
} = slice.actions;



// SERIES
export const startGetAllSeries = () => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    const response = await axios.get(`${baseURL}/series`);
    dispatch(getAllSeries(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    toast.error('Something went wrong.');
  }
};

export const startCreateSeries = (payload, toggle) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.post(`${baseURL}/series`, payload);
    dispatch(createSeries());
    dispatch(startGetAllSeries());
    toggle();
    toast.success('Created');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    toast.error('Something went wrong.');
  }
};

// SEASONS
export const startGetSeriesSeasons = (series_uuid) => async (dispatch) => {
  dispatch(getSeriesSeasons([]));
  dispatch(seriesBegin());
  try {
    const response = await axios.get(`${baseURL}/series-seasons/${series_uuid}`);
    dispatch(getSeriesSeasons(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    toast.error('Something went wrong.');
  }
};

export const startCreateSeriesSeason = (payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.post(`${baseURL}/series-seasons`, payload);
    dispatch(createSeriesSeason());
    dispatch(startGetSeriesSeasons(payload.series_uuid));
    toggle();
    toast.success('Created');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startUpdateSeriesSeason = (series_season_uuid, series_uuid, payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.put(`${baseURL}/series-seasons/${series_season_uuid}`, payload);
    dispatch(updateSeriesSeason());
    dispatch(startGetSeriesSeasons(series_uuid));
    toggle();
    toast.success('Updated');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

// CLASSES
export const startGetClassesBySeriesSeason = (series_season_uuid) => async (dispatch) => {
  dispatch(getClassesBySeriesSeason([]));
  dispatch(seriesBegin());
  try {
    const response = await axios.get(`${baseURL}/classes/ss/${series_season_uuid}`);
    dispatch(getClassesBySeriesSeason(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    toast.error('Something went wrong.');
  }
};

export const startGetClass = (class_uuid) => async (dispatch, getState) => {
  if (class_uuid !== getState().seriesData.currentClass.uuid) {
    dispatch(getClass({
      rosters: [],
      race_teams: [],
      point_systems: [],
      prediction_systems: [],
      bonuses: [],
    }));
  }
  dispatch(seriesBegin());
  try {
    const response = await axios.get(`${baseURL}/classes/${class_uuid}`);
    dispatch(getClass(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    toast.error('Something went wrong.');
  }
};

export const startCreateClass = (payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.post(`${baseURL}/classes`, payload);
    dispatch(createClass());
    dispatch(startGetClassesBySeriesSeason(payload.series_season_uuid));
    toggle();
    toast.success('Created');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startUpdateClass = (class_uuid, payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.put(`${baseURL}/classes/${class_uuid}`, payload);
    dispatch(updateClass());
    dispatch(startGetClass(class_uuid));
    toggle();
    toast.success('Updated');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

// RACERS
export const startGetRacers = (series_uuid) => async (dispatch) => {
  dispatch(getRacers([]));
  dispatch(seriesBegin());
  try {
    const response = await axios.get(`${baseURL}/racers/${series_uuid}`);
    dispatch(getRacers(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    toast.error('Something went wrong.');
  }
};

export const startCreateRacer = (payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.post(`${baseURL}/racers`, payload);
    dispatch(createRacer());
    dispatch(startGetRacers(payload.series_uuid));
    toggle();
    toast.success('Created');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startUpdateRacer = (racer_uuid, payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.put(`${baseURL}/racers/${racer_uuid}`, payload);
    dispatch(updateRacer());
    dispatch(startGetRacers(payload.series_uuid));
    toggle();
    toast.success('Updated');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

// ROSTERS
export const startCreateRoster = (payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.post(`${baseURL}/rosters`, payload);
    dispatch(createRoster());
    toggle();
    dispatch(startGetClass(payload.class_uuid));
    toast.success('Created');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startUpdateRoster = (roster_uuid, payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.put(`${baseURL}/rosters/${roster_uuid}`, payload);
    dispatch(updateRoster());
    toggle();
    dispatch(startGetClass(payload.class_uuid));
    toast.success('Updated');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

// RACER TEAMS
export const startCreateRaceTeam = (payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.post(`${baseURL}/race-teams`, payload);
    dispatch(createRaceTeam());
    toggle();
    dispatch(startGetClass(payload.class_uuid));
    toast.success('Created');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startUpdateRaceTeam = (race_team_uuid, payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.put(`${baseURL}/race-teams/${race_team_uuid}`, payload);
    dispatch(updateRaceTeam());
    toggle();
    dispatch(startGetClass(payload.class_uuid));
    toast.success('Updated');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

// POINT SYSTEMS
export const startCreatePointSystem = (payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.post(`${baseURL}/point-systems`, payload);
    dispatch(createPointSystem());
    toggle();
    dispatch(startGetClass(payload.class_uuid));
    toast.success('Created');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startUpdatePointSystem = (point_system_uuid, payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.put(`${baseURL}/point-systems/${point_system_uuid}`, payload);
    dispatch(updatePointSystem());
    toggle();
    dispatch(startGetClass(payload.class_uuid));
    toast.success('Updated');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startDeletePointSystem = (point_system_uuid, class_uuid, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.delete(`${baseURL}/point-systems/${point_system_uuid}`);
    dispatch(deletePointSystem());
    toggle();
    dispatch(startGetClass(class_uuid));
    toast.warning('Deleted');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

// PREDICTION SYSTEMS
export const startCreatePredictionSystem = (payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.post(`${baseURL}/prediction-systems`, payload);
    dispatch(createPredictionSystem());
    toggle();
    dispatch(startGetClass(payload.class_uuid));
    toast.success('Created');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startUpdatePredictionSystem = (prediction_system_uuid, payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.put(`${baseURL}/prediction-systems/${prediction_system_uuid}`, payload);
    dispatch(updatePredictionSystem());
    toggle();
    dispatch(startGetClass(payload.class_uuid));
    toast.success('Updated');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startDeletePredictionSystem = (prediction_system_uuid, class_uuid, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.delete(`${baseURL}/prediction-systems/${prediction_system_uuid}`);
    dispatch(deletePredictionSystem());
    toggle();
    dispatch(startGetClass(class_uuid));
    toast.warning('Deleted');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

// BONUSES
export const startCreateBonus = (payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.post(`${baseURL}/bonuses`, payload);
    dispatch(createBonus());
    toggle();
    dispatch(startGetClass(payload.class_uuid));
    toast.success('Created');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startUpdateBonus = (bonus_uuid, payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.put(`${baseURL}/bonuses/${bonus_uuid}`, payload);
    dispatch(updateBonus());
    toggle();
    dispatch(startGetClass(payload.class_uuid));
    toast.success('Updated');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startDeleteBonus = (bonus_uuid, class_uuid, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.delete(`${baseURL}/bonuses/${bonus_uuid}`);
    dispatch(deleteBonus());
    toggle();
    dispatch(startGetClass(class_uuid));
    toast.warning('Deleted');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

// ACTIVITY LOGS
export const startGetActivityLogs = (series_season_uuid) => async (dispatch) => {
  dispatch(getActivityLogs([]));
  dispatch(seriesBegin());
  try {
    const response = await axios.get(`${baseURL}/activity-logs/${series_season_uuid}`);
    dispatch(getActivityLogs(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    toast.error('Something went wrong.');
  }
};

export const startCreateActivityLog = (payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.post(`${baseURL}/activity-logs`, payload);
    dispatch(createActivityLog());
    toggle();
    dispatch(startGetActivityLogs(payload.series_season_uuid));
    toast.success('Created');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startUpdateActivityLog = (activity_log_uuid, payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.put(`${baseURL}/activity-logs/${activity_log_uuid}`, payload);
    dispatch(updateActivityLog());
    toggle();
    dispatch(startGetActivityLogs(payload.series_season_uuid));
    toast.success('Updated');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startDeleteActivityLog = (activity_log_uuid, series_season_uuid, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.delete(`${baseURL}/activity-logs/${activity_log_uuid}`);
    dispatch(deleteActivityLog());
    toggle();
    dispatch(startGetActivityLogs(series_season_uuid));
    toast.warning('Deleted');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

// EVENTS
export const startGetEventsBySeriesSeason = (series_season_uuid) => async (dispatch) => {
  dispatch(getEventsBySeriesSeason([]));
  dispatch(seriesBegin());
  try {
    const response = await axios.get(`${baseURL}/events/ss/${series_season_uuid}`);
    dispatch(getEventsBySeriesSeason(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    toast.error('Something went wrong.');
  }
};

export const startGetEvent = (event_uuid) => async (dispatch, getState) => {
  if (event_uuid !== getState().seriesData.currentEvent.uuid) {
    dispatch(getEvent({
      classes: [],
    }));
  }
  dispatch(seriesBegin());
  try {
    const response = await axios.get(`${baseURL}/events/${event_uuid}`);
    dispatch(getEvent(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    toast.error('Something went wrong.');
  }
};

export const startCreateEvent = (payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.post(`${baseURL}/events`, payload);
    dispatch(createEvent());
    toggle();
    dispatch(startGetEventsBySeriesSeason(payload.series_season_uuid));
    toast.success('Created');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startUpdateEvent = (event_uuid, payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.put(`${baseURL}/events/${event_uuid}`, payload);
    dispatch(updateEvent());
    toggle();
    dispatch(startGetEventsBySeriesSeason(payload.series_season_uuid));
    dispatch(startGetEvent(event_uuid));
    toast.success('Updated');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startDeleteEvent = (event_uuid, series_season_uuid, toggle, setSubmitting) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.delete(`${baseURL}/events/${event_uuid}`);
    dispatch(deleteEvent());
    toggle();
    dispatch(startGetEventsBySeriesSeason(series_season_uuid));
    toast.warning('Deleted');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    setSubmitting();
    toast.error('Something went wrong.');
  }
};

export const startRecomputeMatchups = (series_season_uuid, toggle) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.post(`${baseURL}/matchups/recompute/${series_season_uuid}`);
    toggle();
    toast.warning('Series Season Matchups Recomputed');
  } catch (error) {
    console.error(error.response);
    toast.error('Something went wrong.');
  }
  dispatch(seriesEnd());
};

// RACER RESULTS
export const startSubmitRacerResults = (payload, callback) => async (dispatch) => {
  dispatch(seriesBegin());
  try {
    await axios.post(`${baseURL}/racer-results`, payload);
    dispatch(submitRacerResults());
    dispatch(startGetEvent(payload.event_uuid));
    callback();
    toast.success('Submitted');
  } catch (error) {
    console.error(error.response);
    dispatch(seriesEnd());
    toast.error('Something went wrong.');
  }
};

export default slice.reducer;
