import axios from 'axios';
import ls from 'local-storage';
import { toast } from 'react-toastify';

import { logOut } from '../redux/auth';
import { store } from '../app';

// Add a request interceptor
const axiosInstance = axios.create();
axiosInstance.defaults.withCredentials = true;

// RESPONSE INTERCEPTOR
axiosInstance.interceptors.response.use((response) => response, (error) => {
  const { status } = error.response;
  if (status === 401) {
    const { isLoggedIn } = store.getState().auth;
    if (isLoggedIn) {
      toast.warn('Something is wrong. Please log back in.');
    }
    ls.clear();
    store.dispatch(logOut());
    // history.push('/')
  }

  if (status === 498) {
    const originalRequest = error.config;
    return new Promise((resolve, reject) => {
      // Hit the /refresh/access-token route for new accessToken
      axiosInstance.get(`${baseURL}/refresh/access-token`)
        .then(() => {
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          console.err(err);
          return reject(err);
        });
    });
  }

  return Promise.reject(error);
});

export default axiosInstance;
